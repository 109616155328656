import { ThreadsDisplay } from "../types";
import InviteDialog from "./InviteDialog";
import SkeletonThreads from "./SkeletonThreads";
import { ThreadDisplayItem } from "./ThreadDisplayItem";

export default ({
  threads,
  isLoading,
  showAccept,
  invitedUser,
  emptyText,
}: ThreadsDisplay) => {
  if (isLoading) {
    return <SkeletonThreads />;
  }
  if (!threads) {
    return null;
  }

  if (threads.length === 0) {
    return <div className={"noResults"}>{emptyText}</div>;
  }
  return (
    <>
      <div className="areagridcontent">
        {threads.map((t) => {
          return (
            <ThreadDisplayItem
              {...t}
              showAccept={showAccept}
              invitedUser={invitedUser}
            />
          );
        })}
      </div>
      <InviteDialog />
    </>
  );
};
