import SlSkeleton from "@shoelace-style/shoelace/dist/react/skeleton";

export default function SkeletonThreads() {
  const css = `.areagridcontent sl-skeleton{
    height: 300px;
    --border-radius: 5;
    }`;
  return (
    <>
      <div className="areagridcontent">
        <SlSkeleton effect={"pulse"} />
        <SlSkeleton effect={"pulse"} />
        <SlSkeleton effect={"pulse"} />
        <SlSkeleton effect={"pulse"} />
      </div>
      <style>{css}</style>
    </>
  );
}
