import { detect } from "detect-browser";
import { ChosenThread, ThreadInfoDisplay, UserInfo } from "../types";

interface ExtensionPayload<T> {
  payload: T;
  messageCode: string;
  payloadKey: string;
}

interface FirefoxMessage {
  message: any;
  direction: "from" | "to";
  extensionId: string; //minimum security
}

function messageContentScriptFirefox<T>(
  obj: ExtensionPayload<T>,
  extensionId: string,
) {
  const firefoxMessage: FirefoxMessage = {
    message: { message: obj.messageCode, ...obj },
    direction: "to",
    extensionId,
  };
  window.postMessage(firefoxMessage, "*");
}

// window.addEventListener("message", function(event) {
//   if (event.source == window &&
//     event.data.direction &&
//     event.data.direction == "from-content-script") {
//     alert("Page script received message: \"" + event.data.message + "\"");
//   }

async function sendToExtension<T>(obj: ExtensionPayload<T>) {
  const browser = detect();
  const docbookmarkExtensionId = localStorage.getItem("extensionId");
  if (!docbookmarkExtensionId) {
    console.log(
      `Could not find an extension to send the ${obj.messageCode} message to`,
    );
    return;
  }
  try {
    if (browser?.name === "firefox") {
      messageContentScriptFirefox(obj, docbookmarkExtensionId);
      return;
    }
    await chrome.runtime.sendMessage(docbookmarkExtensionId!, {
      message: obj.messageCode,
      [obj.payloadKey]: obj.payload,
    });
  } catch (error) {
    console.error(
      `error when sending the ${obj.messageCode} information to the extension`,
      error,
    );
  }
}

export async function sendLoginInfoToExtension(userInfo: UserInfo) {
  await sendToExtension({
    messageCode: "login",
    payload: userInfo,
    payloadKey: "userInfo",
  });
}

export async function sendLogoffToExtension() {
  await sendToExtension({
    messageCode: "login",
    payload: undefined,
    payloadKey: "userInfo",
  });
}

export async function sendChosenThreadToExtension(chosenThread: ChosenThread) {
  await sendToExtension({
    messageCode: "chosenThread",
    payload: chosenThread,
    payloadKey: "chosenThread",
  });
}

export async function sendThreadDeletedToExtension(
  threadInfo: ThreadInfoDisplay,
) {
  await sendToExtension({
    messageCode: "removeThread",
    payload: threadInfo,
    payloadKey: "removeThread",
  });
}
