import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlInput from "@shoelace-style/shoelace/dist/react/input";
import { useEffect, useState } from "react";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import { passwordStrength } from "check-password-strength";
import { useChangePasswordMutation } from "../apis/threads";

interface NonClosableDialogProps {
  open: boolean;
  label: string;
}

function NonClosableDialog({
  open,
  label,
  children,
}: React.PropsWithChildren<NonClosableDialogProps>) {
  //only property change can close it, unlike shoelace
  if (!open) {
    return null;
  }
  return (
    <div className={"nd-wrapper"}>
      <div className={"nd-modal"}>
        <div className={"nd-title"}>
          <h3>{label}</h3>
        </div>
        {children}
      </div>
    </div>
  );
}

export default function PasswordChanger({
  onClose,
  visible,
}: {
  onClose: () => void;
  visible: boolean;
}) {
  function handleRequestClose(event: any) {
    if (event.detail.source === "overlay") {
      event.preventDefault();
    }
    console.log("calling handleRequestClose close");
  }

  const [changePassword, { isLoading, isSuccess, error, reset }] =
    useChangePasswordMutation();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  function _onClose() {
    console.log("Calling onClose");
    setOldPassword("");
    setNewPassword("");
    setRepeatNewPassword("");
    reset();
    onClose();
  }

  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    setTimeout(() => {
      _onClose();
    }, 3000);
  }, [isSuccess]);

  function handleSubmit(event: any) {
    console.log("Calling handle submit");
    event.preventDefault();
    changePassword({ oldPassword, newPassword });
  }

  const passwordsDontMatch = newPassword !== repeatNewPassword;

  const passwordStrengthBad =
    "" !== newPassword && passwordStrength(newPassword).id === 0;

  const changePasswordDisabled =
    "" === newPassword || passwordsDontMatch || passwordStrengthBad;

  let errMsg: string | undefined;
  if (error) {
    if ("status" in error) {
      errMsg = (error as any).data.message;
    } else {
      errMsg = error.message;
    }
  }
  if (!errMsg) {
    if (passwordsDontMatch) {
      errMsg = "Passwords don't math";
    } else if (passwordStrengthBad) {
      errMsg = "Weak password";
    }
  }

  return (
    <NonClosableDialog label="Change password" open={visible}>
      {isSuccess ? (
        <SlAlert variant={"success"} open={isSuccess}>
          <SlIcon slot="icon" name="check2-circle" />
          <strong>Password changed successfully</strong>
        </SlAlert>
      ) : null}
      <SlAlert variant={"danger"} open={!!errMsg}>
        <SlIcon slot="icon" name="exclamation-octagon" />
        <strong>{errMsg}</strong>
      </SlAlert>
      <br />
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          placeholder="Old password"
          className={"plain-password"}
          value={oldPassword}
          onChange={(ev) =>
            setOldPassword((ev?.target as HTMLInputElement).value)
          }
        />
        <br />
        <input
          type="password"
          placeholder="New password"
          className={"plain-password"}
          value={newPassword}
          onChange={(ev) =>
            setNewPassword((ev?.target as HTMLInputElement).value)
          }
        />
        <br />
        <input
          type="password"
          placeholder="Repeat new password"
          className={"plain-password"}
          value={repeatNewPassword}
          onChange={(ev) =>
            setRepeatNewPassword((ev?.target as HTMLInputElement).value)
          }
        />
        <br />
        <SlButton
          slot="footer"
          variant="primary"
          type={"submit"}
          disabled={changePasswordDisabled || isLoading}
          style={{ marginRight: "5px" }}
        >
          Change password
        </SlButton>
        <SlButton onClick={() => _onClose()} disabled={isLoading}>
          Close
        </SlButton>
      </form>
    </NonClosableDialog>
  );
}
