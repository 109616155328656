import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlTooltip from "@shoelace-style/shoelace/dist/react/tooltip";
import SlButton from "@shoelace-style/shoelace/dist/react/button";
import SlSpinner from "@shoelace-style/shoelace/dist/react/spinner";
import { setCurrentThread, setThreadToShare } from "../slices/threadInfo";
import { useDispatch } from "react-redux";
import { ChosenThread, ThreadInfoDisplayWithAccept } from "../types";
import {
  useAcceptOrRejectInvitationMutation,
  useLazyGetOneThreadQuery,
  useRemoveThreadMutation,
} from "../apis/threads";
import { useEffect } from "react";
import {
  sendChosenThreadToExtension,
  sendThreadDeletedToExtension,
} from "../utils/extensionComunication";

const DELETE = "trash";
const VISIT = "box-arrow-up-right";
const SHARE = "share";
const COMMENTS = "journal-text";
const ACCEPT = "check2-square";
const REJECT = "x-square";
export default function ThreadDisplayActions({
  threadInfo,
}: {
  threadInfo: ThreadInfoDisplayWithAccept;
}) {
  const dispatch = useDispatch();
  const [trigger, result] = useLazyGetOneThreadQuery();

  // const currentUser = useSelector(
  //   (state: RootState) => state.threadInfo.currentUserName
  // );
  const [
    acceptOrReject,
    { data: acceptOrRejectConfirmation, isLoading, isSuccess, error, reset },
  ] = useAcceptOrRejectInvitationMutation();

  const [
    removeThread,
    { isLoading: removeThreadIsLoading, isSuccess: removeThreadSuccess },
  ] = useRemoveThreadMutation();

  async function clickTrigger() {
    await trigger(threadInfo.threadId, false);
  }

  useEffect(() => {
    if (isSuccess) {
      const applicationRoot = `${process.env.REACT_APP_APPLICATION_ROOT}/`;
      window.location.href = applicationRoot!;
    }
  }, [isSuccess]);

  useEffect(() => {
    if (removeThreadSuccess) {
      const timeoutPromise = new Promise((resolve) => {
        setTimeout(resolve, 200, true); //if for some reason the extension doesn't call back, don't block the user
      });
      Promise.race([timeoutPromise, sendThreadDeletedToExtension(threadInfo)])
        .then(() => {
          console.log("Successfully remove thread from extension");
        })
        .catch((err: any) => {
          console.error("Error when removing the thread from extension", err);
        });
    }
  }, [removeThreadSuccess]);

  useEffect(() => {
    // this is the result of trigger (click on open the site from the menu), and it contains the url to open the site
    const data = result?.data;
    if (data?.siteurl) {
      let chosenThread: ChosenThread = {
        url: data.siteurl,
        threadInfo,
      };
      const timeoutPromise = new Promise((resolve) => {
        setTimeout(resolve, 200, true); //if for some reason the extension doesn't call back, don't block the user
      });
      Promise.race([timeoutPromise, sendChosenThreadToExtension(chosenThread)])
        .then(() => {
          window.open(data.siteurl, "_true");
        })
        .catch((err) => {
          console.error(
            "Sending the chosen thread to extension failed with ",
            err,
          );
        });
    }
  }, [result]);
  if (isLoading || removeThreadIsLoading || result.isLoading) {
    return (
      <div className={"threadDisplayAction"}>
        <SlSpinner />
      </div>
    );
  }
  return (
    <div className={"threadDisplayAction"}>
      {threadInfo.showAccept ? (
        <>
          <SlTooltip content={"Accept"}>
            <SlButton
              variant={"text"}
              size={"large"}
              onClick={() =>
                acceptOrReject({
                  invitedUser: threadInfo.invitedUser,
                  threadId: threadInfo.threadId,
                  accept: true,
                })
              }
            >
              <SlIcon name={ACCEPT} />
            </SlButton>
          </SlTooltip>
          <SlTooltip content={"Decline"}>
            <SlButton
              variant={"text"}
              size={"large"}
              onClick={() =>
                acceptOrReject({
                  invitedUser: threadInfo.invitedUser,
                  threadId: threadInfo.threadId,
                  accept: false,
                })
              }
            >
              <SlIcon name={REJECT} />
            </SlButton>
          </SlTooltip>
          <SlTooltip content={"Visit the page"}>
            <SlButton
              variant={"text"}
              size={"large"}
              onClick={() => clickTrigger()}
            >
              <SlIcon name={VISIT} />
            </SlButton>
          </SlTooltip>
        </>
      ) : (
        <>
          <SlTooltip content={"Read the discussion"}>
            <SlButton
              variant={"text"}
              size={"large"}
              onClick={() => dispatch(setCurrentThread(threadInfo))}
            >
              <SlIcon name={COMMENTS} />
            </SlButton>
          </SlTooltip>
          {(threadInfo.threadId.endsWith("_public") || threadInfo.isOwner) && (
            <SlTooltip content={"Share discussion"}>
              <SlButton
                variant={"text"}
                size={"large"}
                onClick={() => dispatch(setThreadToShare(threadInfo))}
              >
                <SlIcon name={SHARE} />
              </SlButton>
            </SlTooltip>
          )}
          <SlTooltip content={"Visit the page"}>
            <SlButton
              variant={"text"}
              size={"large"}
              onClick={() => clickTrigger()}
            >
              <SlIcon name={VISIT} />
            </SlButton>
          </SlTooltip>
          <SlTooltip content={"Stop following the discussion"}>
            <SlButton
              variant={"text"}
              size={"large"}
              onClick={() => removeThread(threadInfo)}
            >
              <SlIcon name={DELETE} />
            </SlButton>
          </SlTooltip>
        </>
      )}
    </div>
  );
}
