import { useGetAllInvitesQuery } from "../apis/threads";
import ThreadDisplay from "../components/ThreadDisplay";

export default function Invitations() {
  const {
    data: threads,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetAllInvitesQuery();
  return (
    <ThreadDisplay
      threads={threads}
      isLoading={isLoading}
      isSuccess={isSuccess}
      isError={isError}
      error={error}
      showAccept={true}
      emptyText={"No pending invitations"}
    />
  );
}
