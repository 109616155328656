import { RootState } from "../store";
import { useSelector } from "react-redux";
import { Comments } from "./Comments";
import { Outlet, useMatch } from "react-router";
import { Link } from "react-router-dom";
import AddToBrowser from "./AddToBrowser";
import logoimg from "../../assets/logo-no-background.svg";

export default function AppHolder({
  noExtension,
  signOut,
}: {
  noExtension: boolean;
  signOut?: () => void;
}) {
  const currentThread = useSelector(
    (state: RootState) => state.threadInfo.currentThread,
  );
  const isInvitations = useMatch("/invitations");
  const isContacts = useMatch("/invited-contacts");
  const isAccount = useMatch("/subscribe");

  return (
    <div className="root">
      <header className="header">
        <div key="logo" className="logo">
          <img src={logoimg} />
        </div>
      </header>
      <div className={"noExtension"}>
        {noExtension && <AddToBrowser key={"addToBrowser"} />}
      </div>
      {!isAccount ? (
        <div className="userInfo">
          <div className="profile">
            <Link to={"/subscribe"}>Account</Link>
          </div>
        </div>
      ) : null}

      <div className="area">
        <Outlet />
      </div>
      <div className={currentThread ? "righto selected" : "righto"}>
        {currentThread && <Comments {...currentThread} />}
      </div>
      {!isAccount ? (
        <nav className="nav">
          <div className={!isContacts && !isInvitations ? "menuSelected" : ""}>
            <Link to="/">Discussions</Link>
          </div>
          <div className={isInvitations ? "menuSelected" : ""}>
            <Link to={"/invitations"}>Invitations</Link>
          </div>
          <div className={isContacts ? "menuSelected" : ""}>
            <Link to={"/invited-contacts"}>Stuff you shared</Link>
          </div>
        </nav>
      ) : null}
    </div>
  );
}
