import { Comment, SerializedTextFragment, ThreadInfoDisplay } from "../types";
import { useGetCommentsQuery, useGetOneThreadQuery } from "../apis/threads";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";
import SlSkeleton from "@shoelace-style/shoelace/dist/react/skeleton";
import { removeCurrentThread } from "../slices/threadInfo";
import { useDispatch } from "react-redux";
import { sanitize } from "isomorphic-dompurify";
import React from "react";
import hash from "object-hash";

function CommentsSkeleton() {
  return (
    <div className="comments-skeleton-paragraphs">
      <SlSkeleton effect={"sheen"} />
      <SlSkeleton effect={"sheen"} />
      <SlSkeleton effect={"sheen"} />
      <SlSkeleton effect={"sheen"} />
      <SlSkeleton effect={"sheen"} />
    </div>
  );
}

function groupComments(comments: Comment[]): Comment[][] {
  const ret: Record<string, Comment[]> = {};
  for (const comment of comments) {
    const uid = hash(comment.highligthedRange);
    const existing = ret[uid];
    if (!existing) {
      ret[uid] = [comment];
    } else {
      ret[uid] = [...existing, comment];
    }
  }
  return Object.values(ret);
}

function getSelection(comment: Comment) {
  if (!Array.isArray(comment.highligthedRange)) {
    return comment.highligthedRange.selection;
  }
  const flatHighlights: SerializedTextFragment[] = [];
  for (const c of comment.highligthedRange) {
    for (const cc of c) {
      flatHighlights.push(cc);
    }
  }
  return flatHighlights.map((f) => f.selectedText).join(" ");
}

export function Comments(threadInfo: ThreadInfoDisplay) {
  const dispatch = useDispatch();
  const {
    data: comments,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetCommentsQuery(threadInfo.threadId);

  const { data: oneThread, isLoading: oneThreadLoading } = useGetOneThreadQuery(
    threadInfo.threadId,
  );

  const groupedByHighlighted = comments && groupComments(comments);
  return (
    <div className={"visibleComments"}>
      <div
        className="closeButton"
        onClick={() => dispatch(removeCurrentThread())}
      >
        <SlIcon name={"x-lg"} />
      </div>
      {isLoading || oneThreadLoading ? (
        <div className={"inner-visible-comments"}>
          <CommentsSkeleton />
        </div>
      ) : (
        <div className={"inner-visible-comments"}>
          <div className={"threadDescription"}>{oneThread?.pageTitle}</div>

          {oneThread &&
            groupedByHighlighted?.map((cc: Comment[]) => {
              return (
                <>
                  <div className={"threadHighlighted"}>
                    {getSelection(cc[0])}
                  </div>
                  {cc.map((c) => (
                    <div className={"oneComment"}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: sanitize(c.comment),
                        }}
                      ></div>
                      <div className={"author"}>
                        <div>{c.displayname}</div>
                        <small className="thread-start">
                          {new Date(c.commentDate).toLocaleDateString()}
                        </small>
                      </div>
                    </div>
                  ))}
                </>
              );
            })}
        </div>
      )}
    </div>
  );
}
