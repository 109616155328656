import { ThreadInfoDisplayWithAccept } from "../types";
import SlCard from "@shoelace-style/shoelace/dist/react/card";
import ThreadDisplayActions from "./ThreadDisplayActions";
import { PropsWithChildren, useState } from "react";
import penandpaper from "../../assets/penandpaper.jpg";

export function ThreadDisplayItem(threadInfo: ThreadInfoDisplayWithAccept) {
  const thumbnailRoot = process.env.REACT_APP_THUMBNAILS_ROOT;
  return (
    <ThreadDisplayItemHolder {...threadInfo}>
      <ThreadDisplayActions threadInfo={threadInfo} />
    </ThreadDisplayItemHolder>
  );
}

export function ThreadDisplayItemHolder(
  threadInfo: PropsWithChildren<ThreadInfoDisplayWithAccept>,
) {
  const thumbnailRoot = process.env.REACT_APP_THUMBNAILS_ROOT;
  const [invalidImage, setInvalidImage] = useState(false);
  return (
    <>
      <SlCard className="card-overview">
        <img
          slot="image"
          src={
            threadInfo.thumbnailKey && !invalidImage
              ? thumbnailRoot + threadInfo.thumbnailKey
              : penandpaper
          }
          onError={() => setInvalidImage(true)}
          alt={threadInfo.pageTitle}
        />
        <div>
          <small>{threadInfo.pageTitle}</small>
        </div>
        <strong>{threadInfo.threadName}</strong>
        <small className="thread-start">
          {new Date(threadInfo.actionDate).toLocaleDateString()}
        </small>
        {threadInfo.showAccept && (
          <div className={"inviteText"}>
            {threadInfo.inviterDisplayName} invited you
          </div>
        )}
        <div slot="footer">{threadInfo.children}</div>
      </SlCard>
    </>
  );
}
