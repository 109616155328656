import { configureStore } from "@reduxjs/toolkit";

import { setupListeners } from "@reduxjs/toolkit/query";
import { threadInfoApi } from "./apis/threads";
import threadInfoReducer from "./slices/threadInfo";
import { threadSlice } from "./slices/threadInfo";

const signOutActionType = threadSlice.actions.setSignOutFunction.type;
export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [threadInfoApi.reducerPath]: threadInfoApi.reducer,
    threadInfo: threadInfoReducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [signOutActionType],
        // Ignore these paths in the state
        ignoredPaths: ["threadInfo.signOutFunction"],
      },
    }).concat(threadInfoApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
