import { useGetInviteForATemporaryUserQuery } from "../apis/threads";
import { useParams } from "react-router";
import ThreadDisplay from "../components/ThreadDisplay";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

export function Invite() {
  const { userId } = useParams();
  const _userId = userId!;
  const {
    data: threads,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetInviteForATemporaryUserQuery(_userId);

  if (!userId) {
    return null;
  }
  return (
    <>
      <ThreadDisplay
        threads={threads}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        error={error}
        invitedUser={_userId}
        showAccept={true}
        emptyText={"No invitation"}
      />
      {isError && (
        <SlAlert variant="danger" open>
          <SlIcon slot="icon" name="exclamation-octagon" />
          <strong>Your account has been deleted</strong>
          <br />
          We're very sorry to see you go!
        </SlAlert>
      )}
    </>
  );
}
