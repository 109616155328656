import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { createHashRouter, RouterProvider } from "react-router-dom";
import Home from "./app/routes/Home";
import Invitations from "./app/routes/invitations";
import { Invite } from "./app/routes/invite";
import InvitedContacts from "./app/routes/invitedContacts";
import SubscriptionCheckout from "./app/routes/SubscriptionCheckout";

const rootDomEl = document.getElementById("root") as HTMLElement;

const router = createHashRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/invitations",
        element: <Invitations />,
      },
      {
        path: "/invite/:userId",
        element: <Invite />,
      },
      {
        path: "/invited-contacts",
        element: <InvitedContacts />,
      },
      { path: "/subscribe", element: <SubscriptionCheckout /> },
    ],
  },
]);

const AppContainer = () => (
  <Provider store={store}>
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  </Provider>
);

const root = rootDomEl.hasChildNodes()
  ? ReactDOM.hydrateRoot(rootDomEl, <AppContainer />)
  : ReactDOM.createRoot(rootDomEl);

root.render(<AppContainer />);

declare global {
  interface Window {
    tokenAWS: string;
  }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
