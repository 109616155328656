import "./App.css";
import "@shoelace-style/shoelace/dist/themes/light.css";
import { setBasePath } from "@shoelace-style/shoelace/dist/utilities/base-path";

import { Amplify, Auth, Hub } from "aws-amplify";
import { CognitoUserSession } from "amazon-cognito-identity-js";
import { useEffect, useLayoutEffect, useState } from "react";

import { withAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import AppHolder from "./app/components/AppHolder";

import { setCurrentUser, setSignOutFunction } from "./app/slices/threadInfo";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { useGetSubscriptionInfoQuery } from "./app/apis/threads";
import { UserInfo } from "./app/types";
import {
  sendLoginInfoToExtension,
  sendLogoffToExtension,
} from "./app/utils/extensionComunication";

setBasePath(
  "https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.0.0/dist/",
);

const updatedAwsExports = {
  ...awsExports,
  redirectSignIn: "localhost:3000",
  redirectSignOut: "localhost:3000",
};
Amplify.configure(updatedAwsExports);

type AmplifyProps = {
  isPassedToWithAuthenticator?: any;
  signOut?: any;
  user?: any;
};

async function verifyUser() {
  // Instead of creating the Cognito callback in aws(very difficult), we call this after login to insert the user data if not already exist
  const currentSession = await Auth.currentSession();
  const accessToken = currentSession.getAccessToken()?.getJwtToken();
  await fetch(`${process.env.REACT_APP_ROOT_API_URL}/verify-user`, {
    credentials: "include",
    method: "POST",
    headers: new Headers({ authorization: `Bearer ${accessToken}` }),
  });
}

function App(props?: AmplifyProps) {
  const dispatch = useDispatch();
  const location = useLocation();

  let [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [noExtension, setNoExtension] = useState(false);
  const { data: subscriptionInfo } = useGetSubscriptionInfoQuery(undefined, {
    skip: !userInfo,
  });
  const getUserInfo = async () => {
    try {
      const userSession: CognitoUserSession = await Auth.currentSession();
      const decodedTokenPayload = userSession.getIdToken().decodePayload();
      let idTokenPayload: {
        name?: string;
        sub?: string;
      } = decodedTokenPayload;

      //const userName = decodedTokenPayload["congnito:username"];
      console.log(decodedTokenPayload);
      const _userInfo: UserInfo = {
        name: idTokenPayload.name,
        sub: idTokenPayload.sub ?? "invalidsub",
        jwt: userSession.getAccessToken().getJwtToken(),
        refreshToken: userSession.getRefreshToken().getToken(),
      };
      setUserInfo(_userInfo);
      await verifyUser();
      localStorage.setItem("cognitoUserInfo", JSON.stringify(_userInfo));
      const devices = await Auth.currentSession();
      console.log(devices);
      sendLoginInfoToExtension(_userInfo).catch((err) => {
        console.error(err);
      });
    } catch (e: any) {
      console.error("Error when getting the user info", e);
    }
  };

  const { isPassedToWithAuthenticator, signOut, user } = props!;
  useEffect(() => {
    console.log(`Get user info for the user ${user}`);
    console.log(user);
    dispatch(setCurrentUser(user.username));
    getUserInfo().catch((err) => console.error(err));
  }, [user]);
  useEffect(() => {
    if (location.search && location.search?.indexOf("extensionId")) {
      const searchParams = new URLSearchParams(location.search);
      localStorage.setItem("extensionId", searchParams.get("extensionId")!);
      localStorage.setItem("searchQry", location.search);
    }
  }, [location]);
  useEffect(() => {
    if (signOut) {
      dispatch(setSignOutFunction(signOut));
    }
  }, [signOut]);
  useEffect(() => {
    Auth.currentUserInfo().then((ui) => {
      if (!ui || !subscriptionInfo) {
        return;
      }
      localStorage.setItem(
        "subscriptionInfo",
        JSON.stringify(subscriptionInfo),
      );
    });
  }, [subscriptionInfo]);
  useEffect(() => {
    if (userInfo) {
    }
  }, [userInfo]);
  useLayoutEffect(() => {
    let el = document.getElementsByTagName(
      "display-comments",
    )[0] as HTMLElement | null;
    if (!el) {
      // new version
      el = document.getElementById("markAndNoteExtensionRoot");
    }
    const extensionId = el?.dataset.extid;
    if (extensionId) {
      localStorage.setItem("extensionId", extensionId);
    } else {
      setNoExtension(true);
      localStorage.setItem("noExtension", "true"); // for the webpage (outside this application) to display this
    }
  }, []);
  if (!props) return <div />;
  if (!isPassedToWithAuthenticator) {
    console.log(`isPassedToWithAuthenticator was not provided`);
  }

  return <AppHolder noExtension={noExtension} signOut={signOut} />;
}

declare global {
  interface Window {
    secret: string;
  }
}

export default withAuthenticator<AmplifyProps>(App);

export async function getStaticProps() {
  return {
    props: {
      isPassedToWithAuthenticator: true,
    },
  };
}

Hub.listen("auth", (data) => {
  switch (data.payload.event) {
    case "signin":
      console.log("Amplify sign in event");
      localStorage.removeItem("cognitoLoggedOut");
      break;
    case "signOut":
      console.log("removing congitoUserInfo");
      localStorage.removeItem("cognitoUserInfo");
      sendLogoffToExtension().catch((err) => console.error(err));
      break;
    case "oAuthSignOut":
      localStorage.removeItem("cognitoUserInfo");
      sendLogoffToExtension().catch((err) => console.error(err));
      break;
    case "signIn_failure":
      localStorage.removeItem("cognitoUserInfo");
      break;
  }
});
