import SlButton from "@shoelace-style/shoelace/dist/react/button";
import { detect } from "detect-browser";
import SlAlert from "@shoelace-style/shoelace/dist/react/alert";
import SlIcon from "@shoelace-style/shoelace/dist/react/icon";

const css = `
  .card-footer {
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export default function AddToBrowser() {
  if (/Android|iPhone/i.test(navigator.userAgent)) {
    // This checks if the current device is a mobile device
    return null; // no extensions for mobile browsers (yet)
  }
  const browser = detect();
  let buttonName: string | undefined = undefined;
  let extensionUrl: string | undefined = undefined;
  if (browser) {
    if (browser.name === "chrome") {
      buttonName = "Chrome";
      extensionUrl =
        "https://chromewebstore.google.com/detail/mark-and-note/glknaloegapcddfimfmfmcmipgmophpb";
    } else if (browser.name === "edge" || browser.name === "edge-chromium") {
      extensionUrl =
        "https://chromewebstore.google.com/detail/mark-and-note/glknaloegapcddfimfmfmcmipgmophpb";
      buttonName = "Edge";
    } else if (browser.name === "firefox") {
      buttonName = "Firefox";
      extensionUrl =
        "https://addons.mozilla.org/en-US/firefox/addon/markandnote"; //TODO check after publishing
    }
  }
  const el = document.getElementsByTagName(
    "display-comments",
  )[0] as HTMLElement;
  const extensionId = el?.dataset.extid;
  if (extensionId) {
    localStorage.setItem("extensionId", extensionId);
    return null;
  } else {
    localStorage.setItem("noExtension", "true"); // for the webpage (outside this application) to display this
  }
  if (!buttonName) {
    return (
      <SlAlert variant="warning" open>
        <SlIcon slot="icon" name="exclamation-triangle" />
        Mark and Note currently doesn't work on Safari yet, coming soon
      </SlAlert>
    );
  }
  return (
    <>
      <SlAlert variant="primary" open>
        <SlIcon slot="icon" name="info-circle" />
        <div className="card-footer">
          <div>Note, discuss and share. Works on any website.</div>
          <div>
            <SlButton slot="footer" variant="primary" href={extensionUrl}>
              {buttonName && `Add to ${buttonName}`}
            </SlButton>
          </div>
        </div>
      </SlAlert>
      <style>{css}</style>
    </>
  );
}
