import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  AcceptInvite,
  Comment,
  Invite,
  Location,
  SubscriptionInfo,
  ThreadInfo,
  ThreadInfoDisplay,
  ThreadInfoDisplayWithAccept,
  ChangePassword,
} from "../types";
import { Auth } from "aws-amplify";

const rootApiURL = process.env.REACT_APP_ROOT_API_URL;
export const threadInfoApi = createApi({
  reducerPath: "threadInfoApi",
  tagTypes: [
    "Threads",
    "Invitations",
    "Invited",
    "Comments",
    "SubscriptionInfo",
  ],
  baseQuery: fetchBaseQuery({
    baseUrl: rootApiURL,
    credentials: "include",
    prepareHeaders: async (headers) => {
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken()?.getJwtToken();
      if (accessToken) {
        headers.set("authorization", "Bearer " + accessToken);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAllAccessibleThreads: builder.query<ThreadInfoDisplay[], void>({
      query: () => `get-threads`,
      providesTags: (result, error, arg) => ["Threads"],
    }),
    getThreadsForUrl: builder.query<ThreadInfoDisplay[], string>({
      query: (url: string) => `get-threads/${url}`,
      providesTags: (result, error, arg) => [{ type: "Threads", id: arg }],
    }),
    getComments: builder.query<Comment[], string>({
      query: (threadId: string) => `get-bookmark-data?threadId=${threadId}`,
      providesTags: (result, error, arg) => [{ type: "Comments", id: arg }],
    }),
    getOneThread: builder.query<ThreadInfo, string>({
      query: (threadId: string) => `get-one-thread?threadId=${threadId}`,
    }),
    getAllInvites: builder.query<ThreadInfoDisplay[], void>({
      query: () => `get-invites`,
      providesTags: ["Invitations"],
    }),
    getInviteForATemporaryUser: builder.query<ThreadInfoDisplay[], string>({
      query: (temporaryUser: string) => `get-invites?user=${temporaryUser}`,
      providesTags: ["Invitations"],
    }),
    getInvited: builder.query<ThreadInfoDisplayWithAccept[], void>({
      query: () => `get-invited`,
      providesTags: ["Invited"],
    }),
    inviteUser: builder.mutation<string, Invite>({
      query: (invitation: Invite) => ({
        url: "invite-user",
        method: "POST",
        body: invitation,
      }),
      invalidatesTags: ["Invited"],
    }),
    acceptOrRejectInvitation: builder.mutation<void, AcceptInvite>({
      query: (acceptInvite: AcceptInvite) => ({
        url: "accept-or-reject-thread-invitation",
        method: "POST",
        body: acceptInvite,
      }),
      invalidatesTags: ["Invitations"],
    }),
    removeThread: builder.mutation<void, ThreadInfoDisplay>({
      query: (threadInfo: ThreadInfoDisplay) => ({
        url: `delete-thread?threadId=${threadInfo.threadId}`,
        method: "POST",
      }),
      invalidatesTags: ["Threads"],
    }),
    getSubscriptionInfo: builder.query<SubscriptionInfo, void>({
      query: () => `get-user-data`,
      providesTags: ["SubscriptionInfo"],
    }),
    startSubscription: builder.mutation<Location, string>({
      query: (productId: string) => ({
        url: `start-subscription?productid=${productId}`,
        method: "POST",
      }),
      invalidatesTags: ["SubscriptionInfo"],
    }),
    changeSubscription: builder.mutation<Location, void>({
      query: () => ({
        url: `update-subscription`,
        method: "POST",
      }),
      invalidatesTags: ["SubscriptionInfo"],
    }),
    upgradeSubscription: builder.mutation<void, string>({
      query: (priceId: string) => ({
        url: `upgrade-subscription?priceId=${priceId}`,
        method: "POST",
      }),
      invalidatesTags: ["SubscriptionInfo"],
    }),
    changePassword: builder.mutation<void, ChangePassword>({
      query: (changePassword: ChangePassword) => ({
        url: "change-password",
        method: "POST",
        body: changePassword,
      }),
      invalidatesTags: ["SubscriptionInfo"],
    }),
  }),
});

export const {
  useGetAllAccessibleThreadsQuery,
  useGetThreadsForUrlQuery,
  useGetCommentsQuery,
  useGetAllInvitesQuery,
  useGetInviteForATemporaryUserQuery,
  useGetInvitedQuery,
  useInviteUserMutation,
  useAcceptOrRejectInvitationMutation,
  useRemoveThreadMutation,
  useLazyGetOneThreadQuery,
  useGetOneThreadQuery,
  useStartSubscriptionMutation,
  useGetSubscriptionInfoQuery,
  useChangeSubscriptionMutation,
  useUpgradeSubscriptionMutation,
  useChangePasswordMutation,
} = threadInfoApi;
